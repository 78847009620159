



















.success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .success-text {
        margin-top: 48px;
        font-size: 24px;
        color: #060111;
    }

    .el-button {
        margin-top: 44px;
    }
}
