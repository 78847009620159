





















































































































































































































































































































































































































































































.title-item {
    font-size: 16px;
    font-weight: 500;
    color: #060111;
    position: relative;
    padding-left: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 12px;
        background: #2821FC;
        border-radius: 3px;
        left: 0;
        top: 5px;
    }

    .button-box {
        width: 96px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #2821FC;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #2821FC;
        position: absolute;
        right: 0;
        top: -10px;
        font-size: 14px;
    }
}

.item-input {
    &.max {
        width: 210px;
    }

    &.medium {
        width: 88px;
    }

    &.mini {
        width: 80px;
    }
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

.setting-box {
    margin-top: 40px;

    &:first-child {
        margin-top: 0;
    }
}

.item-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.even-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.odd-content {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    position: relative;

    .odd-item {
        display: flex;
        align-items: center;
        margin-left: 40px;

        &:nth-of-type(2) {
            margin-left: 0;
        }

        .el-input-number {
            margin-left: 0;
        }
    }
}

.item-title {
    width: 120px;
    text-align: right;
    margin-right: 10px;
    color: #1B162A;

    &:before {
        content: '*';
        color: #ff3e6c;
        margin-right: 4px;
    }
}

.input-text {
    margin: 0 10px;
}

.divide {
    width: 15px;
    height: 2px;
    background: #D2D2D2;
    margin: 0 4px;
}

.el-input-number {
    margin-left: 40px;

    &:nth-of-type(2) {
        margin-left: 0;
    }
}

.item-column-content {
    display: flex;
    flex-wrap: wrap;

    .column-item {
        margin-left: 5px;

        &:first-child {
            margin-left: 0 !important;

            .item-content-title {
                margin-left: 120px;
            }
        }

        .odd-item {
            margin-left: 0;
        }

        .odd-content {
            margin-top: 20px;
        }

        &:nth-of-type(6n + 1) {
            margin-left: 130px;
        }
    }
}

.item-content-title {
    color: #1B162A;
    margin-top: 10px;
    width: 210px;
    text-align: center;
}

.del-btn {
    position: absolute;
    cursor: pointer;
    color: #FF3E6C;
    left: 0;
    top: 0;
}
